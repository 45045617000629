<template>
    <head>
        <title>Hi!</title>
    </head>
    <body class="h-screen bg-dots">
        <div class="p-4 text-center flex w-full">
            <HelloWorld msg="Welcome to Your Vue.js App" />
        </div>
    </body>
</template>

<script>
import "./index.css";
import HelloWorld from "./components/HelloWorld.vue";

export default {
    name: "App",
    components: {
        HelloWorld,
    },
};
</script>

<style scoped>
.bg-dots {
    background: url("./assets/body_bg.png");
}
</style>
